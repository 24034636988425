<template>
    <TransitionRoot
        as="template"
        :show="show">
        <Dialog
            as="div"
            class="relative z-[60]"
            @close="show = false">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-[60] w-screen overflow-y-auto">
                <div class="flex min-h-full justify-center p-4 text-center items-center">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-700 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:p-6"
                            :class="{'max-w-sm': size === 'sm', 'max-w-md': size === 'md', 'max-w-2xl': size === 'lg', 'max-w-4xl': size === 'xl'}">
                            <div
                                v-if="dismissButton"
                                class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    id="dismissButton"
                                    type="button"
                                    class="rounded-md text-gray-400 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-100"
                                    @click="show = false">
                                    <span class="sr-only">Close</span>
                                    <Icon
                                        name="heroicons:x-mark"
                                        class="w-6 h-6" />
                                </button>
                            </div>
                            <div>
                                <DialogTitle as="h3">
                                    {{ title }}
                                </DialogTitle>
                                <div class="mt-2">
                                    <div
                                        v-if="loading"
                                        class="w-100 flex justify-center">
                                        <LoadingSpinner size="lg" />
                                    </div>
                                    <template v-else>
                                        <slot />
                                    </template>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import LoadingSpinner from '@/components/global/LoadingSpinner'

const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    dismissButton: {
        type: Boolean,
        default: true
    },
    size: {
        type: String,
        default: 'xl'
    },
    modelValue: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['update:modelValue'])

const show = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emit('update:modelValue', newValue)
    }
});

</script>

<style scoped>

</style>